@import '@scss/service';

$mob: $sm;
$tab: $xxs, $xl;

.fieldsContainer {
  width: 100%;
  // max-width: 420px;
  margin: auto;
  @include respond(xs) {
    width: 100%;
  }
}

.authorization {
  width: 100%;
  // max-width: $sm;
  text-align: center;

  .fieldset {
    border: none;
    padding: 0;
    display: grid;
    grid-row-gap: 8px;

    @include respond(xs) {
      width: auto;
    }
  }

  .inputRedesign {
    @extend %input-redesign;
  }

  .loan {
    &ButtonWrap {
      display: flex;
      min-height: 59px;
      margin-top: 8px;
      background-color: $white;
    }

    &Button {
      @extend %button-gradient;
      margin: auto;
    }
  }

  //immitation of LoanObtainingButton
  .float {
    &Panel {
      position: fixed;
      z-index: 10;
      bottom: 0;
      left: 80px;
      width: 100%;
      max-width: 360px;
      max-height: 0;
      transition: all 0.2s ease;
      border-radius: 16px 16px 0px 0px;
      background: $loan-button-gradient;
      @include respond($tab) {
        left: 0;
        max-width: none;
      }

      &.show {
        display: block;
        max-height: 185px;
      }

      .holder {
        display: flex;
        padding: 0 6px 0 16px; // 0 0 0 16px;
        height: 185px;
        justify-content: center; //space-between;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          row-gap: 24px;

          @include respond($tab) {
            row-gap: 10px;
          }

          .text {
            text-align: left;
            color: $white;
          }
          .text.top{
            font-size: 16px;
            max-width: 260px;
            
            @include respond($mob) {
              font-size: 14px;
              max-width: 180px;
            }
          }
          .text.bottom{
            font-size: 12px;
            max-width: 200px;
          }

          .button {
            display: flex;
            min-height: 55px;
          }
        }

        .right {
          display: flex;
          flex-shrink: 0;
          align-items: flex-end;
        }
      }
    }

    &ApplyButtonPanel {
      position: fixed;
      z-index: 10;
      bottom: 0;
      left: 80px;
      width: 100%;
      max-width: 360px;
      max-height: 0;
      transition: all 0.2s ease;
      //border-radius: 16px 16px 0px 0px;
      padding: 16px 0 16px 0;
      border-top: #BFC6DC 1px solid;
      background-color: #F4F5F9;
      @include respond($tab) {
        left: 0;
        max-width: none;
      }

      &.show {
        display: block;
        max-height: 185px;
      }

      .holder {
        display: flex;
        padding: 0 6px 0 16px; // 0 0 0 16px;
        height: 185px;
        justify-content: center; //space-between;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          row-gap: 24px;

          .text {
            text-align: left;
            color: $white;
          }

          .button {
            display: flex;
            min-height: 55px;
          }
        }

        .right {
          display: flex;
          flex-shrink: 0;
          align-items: flex-end;
        }
      }

      .loanButtonWrap {
        margin: 0;
        background-color: #F4F5F9;

        .loanButton {
          width: calc(100% - 32px);
          max-width: none;
        }
      }
    }

    &Button {
      font-weight: 600;
      color: $white;
      background: $yellow-gradient;
      padding: 16px 32px;
      margin: auto;
      border: none;
      &:hover:not(:disabled) {
        background: $yellow-gradient;
        padding: 18px 32px;
      }
    }
  }
}

.policyTab {
  width: 100%;
  text-align: justify;
  font-size: 12px;
  color: $re-gray;
  margin-top: 8px;

  a {
    color: $re-blue;
    text-decoration: underline;

    &:hover {
      color: $re-blue-dark;
    }
  }
}

.marketingConfirmation {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  color: $re-gray;

  // & .agree_check {
  //   margin-top: 0.2em;
  // }

  & .agree_text {
    text-align: justify;

    .link {
      color: $blue;
    }
  }
}

.loyaltyCode {
  margin-top: 8px;
  background-color: $white;
}
